
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import sedes from "@/services/sedes.service";
// import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "ordenar-area",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Crear área", ["Pages", "Equipos"]);
    });
    return {};
  },
});
